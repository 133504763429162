export const SNOWPACK_PUBLIC_PACKAGE_VERSION = "0.19.0";
export const SNOWPACK_PUBLIC_BACKEND_URL = "https://us-central1-relar-production.cloudfunctions.net";
export const SNOWPACK_PUBLIC_API_KEY = "AIzaSyD97uIVRscdD52gGzxA_cRfTAGdsmEFRbA";
export const SNOWPACK_PUBLIC_AUTH_DOMAIN = "relar-production.firebaseapp.com";
export const SNOWPACK_PUBLIC_PROJECT_ID = "relar-production";
export const SNOWPACK_PUBLIC_STORAGE_BUCKET = "relar-production.appspot.com";
export const SNOWPACK_PUBLIC_MESSAGING_SENDER_ID = "891497953282";
export const SNOWPACK_PUBLIC_APP_ID = "1:891497953282:web:e4261f60a2782ff2338f1b";
export const SNOWPACK_PUBLIC_MEASUREMENT_ID = "G-8GV88GECD8";
export const SNOWPACK_PUBLIC_SENTRY_DSN = "https://ae6c432b2c074f17b223ddd11df69461@o400394.ingest.sentry.io/5258806";
export const MODE = "production";
export const NODE_ENV = "production";
export const SSR = false;